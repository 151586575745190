import GCPIcon from '@/assets/gcp.svg';
import AzureIcon from '@/assets/azure.svg';
import ByocIcon from '@/assets/byoc-label.svg';
import { ByocSelected, AWS } from '@/pages/home/icons';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';

export enum CloudPlatform {
  GCP = 'GCP',
  AWS = 'AWS',
  Azure = 'AZURE',
}

interface Props extends Partial<CloudProviderT> {
  selected: boolean;
  disabled: boolean;
  onSelect?: () => void;
}

const cloudProviderIconMap: Record<CloudPlatform, { name: string; icon?: string; Component?: typeof AWS }> = {
  GCP: {
    name: 'GCP',
    icon: GCPIcon,
  },
  AWS: {
    name: 'AWS',
    Component: AWS,
  },
  AZURE: {
    name: 'AZURE',
    icon: AzureIcon,
  },
};

export function PlatformIcon({
  platform = 'AWS',
  height,
  width,
}: {
  platform?: string;
  height?: number;
  width?: number;
}) {
  platform = platform.toUpperCase();
  const [css] = useStyletron();
  const Component = cloudProviderIconMap[platform as CloudPlatform].Component;

  return Component ? (
    <Component width={width} height={height} />
  ) : (
    <img
      className={css({
        height: `${height ? `${height}px` : 'auto'}`,
        width: `${width ? `${width}px` : 'auto'}`,
      })}
      src={cloudProviderIconMap[platform as CloudPlatform].icon as string}
      alt={cloudProviderIconMap[platform as CloudPlatform].name}
    />
  );
}

export default function CloudProviderCategory({ selected, disabled, onSelect, ...cloudProvider }: Props) {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
        width: '201px',
        height: '84px',
        boxSizing: 'border-box',
        borderWidth: '2px',
        borderColor: selected ? theme.colors['card.border.selected'] : theme.colors['card.border'],
        position: 'relative',
      })}
      onClick={() => {
        if (!disabled) {
          onSelect?.();
        }
      }}
    >
      {selected && (
        <div className={css({ position: 'absolute', top: '-1px', right: '-2px' })}>
          <ByocSelected />
        </div>
      )}
      {cloudProvider.type !== 'public' && (
        <img src={ByocIcon} alt="byoc" className={css({ position: 'absolute', top: '0', left: '0' })} />
      )}
      <div
        className={css({
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <PlatformIcon platform={cloudProvider.platform} />
      </div>
      <div
        className={css({
          fontSize: '12px',
          fontWeight: 700,
          lineHeight: '16px',
          textAlign: 'center',
          marginTop: '8px',
          color: theme.colors['card.text.primary'],
        })}
      >
        {cloudProvider.type === 'public' ? (
          cloudProvider.id === 'azure' || cloudProvider.id === 'gcp' ? (
            <span>
              {cloudProvider.id === 'azure' ? 'Azure' : 'GCP'}{' '}
              <span
                className={css({
                  fontSize: '12px',
                  fontWeight: 400,
                })}
              >
                (Coming Soon)
              </span>
            </span>
          ) : (
            cloudProvider.platform
          )
        ) : (
          cloudProvider.name
        )}
      </div>
    </div>
  );
}
