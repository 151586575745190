import { fetchLog, fetchComponentList, fetchHostList } from './api';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { LogFilter, LogResult } from './type';

export function useQueryLogComponentList(
  workgroupID: string,
  workspaceID: string,
  options?: Omit<UseQueryOptions<string[], AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<string[], AxiosError>(
    [workgroupID, workspaceID, 'log'],
    async () => {
      return fetchComponentList(workgroupID, workspaceID);
    },
    options
  );
}

export function useQueryLogHostList(
  workgroupID: string,
  workspaceID: string,
  options?: Omit<UseQueryOptions<string[], AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<string[], AxiosError>(
    [workgroupID, workspaceID, 'host'],
    async () => {
      return fetchHostList(workgroupID, workspaceID);
    },
    options
  );
}

export function useQueryLogs(
  workgroupID: string,
  workspaceID: string,
  logFilter: LogFilter,
  options?: Omit<UseQueryOptions<LogResult, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery<LogResult, AxiosError>(
    [workgroupID, workspaceID, 'log', logFilter],
    async () => {
      return fetchLog(workgroupID, workspaceID, logFilter);
    },
    options
  );
}
