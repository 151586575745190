import { FormControlOverrides, FormControlProps } from 'baseui/form-control';
import { FormControl as BaseFormControl } from '@tigergraph/app-ui-lib/form-control';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        ...theme.typography.Body1,
        marginTop: '-8px',
        fontWeight: 500,
      };
    },
  },
};

export function FormControl({ overrides, error, ...props }: FormControlProps) {
  return (
    <BaseFormControl
      {...props}
      error={error || undefined}
      overrides={mergeOverrides(formControlOverrides as Overrides<unknown>, overrides as Overrides<unknown>)}
    />
  );
}
