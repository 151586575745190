import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useMemo, useState } from 'react';
import { Search } from 'baseui/icon';
import { Select } from '@tigergraph/app-ui-lib/select';
import { Value } from 'baseui/select';
import { DatePicker } from '@tigergraph/app-ui-lib/datepicker';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { addDays, addMinutes, format } from 'date-fns';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Body1 } from '@tigergraph/app-ui-lib/typography';
import { Pagination } from '@tigergraph/app-ui-lib/pagination';
import useDebounce from 'ahooks/lib/useDebounce';
import { ErrorDisplay } from '@/components/error';
import { QuickSelectOption } from 'baseui/datepicker';
import { Log, LogFilter } from './type';
import { useQueryLogComponentList, useQueryLogHostList, useQueryLogs } from './hook';
import { WorkGroupT } from '@/pages/workgroup/type';
import { SpaceIcon } from '@/pages/home/icons';
import { calculateRoleForSpace } from '@/pages/admin/user/type';
import { useOrgContext } from '@/contexts/orgContext';

const pageSize = 8;

const getCurrentDate = () => {
  const currentDate = new Date();
  const min = currentDate.getMinutes();
  const finalMin = [0, 15, 30, 45, 60].find((i) => min < i);
  currentDate.setMinutes(finalMin || 60, 0, 0);
  return currentDate;
};

export function WorkspaceLog({ group }: { group: WorkGroupT }) {
  const { userInfo } = useOrgContext();
  const [css, theme] = useStyletron();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, { wait: 500 });
  const quickSelectOptions = useMemo(() => {
    const currentDate = new Date();
    const quickSelectOptions: QuickSelectOption[] = [
      {
        id: 'Past 5 Mins',
        beginDate: new Date(addMinutes(currentDate, -5)),
        endDate: currentDate,
      },
      {
        id: 'Past 15 Mins',
        beginDate: new Date(addMinutes(currentDate, -15)),
        endDate: currentDate,
      },
      {
        id: 'Past 45 Mins',
        beginDate: new Date(addMinutes(currentDate, -45)),
        endDate: currentDate,
      },
      {
        id: 'Past Hour',
        beginDate: new Date(addMinutes(currentDate, -60)),
        endDate: currentDate,
      },
      {
        id: 'Past 24 Hours',
        beginDate: new Date(addDays(currentDate, -1)),
        endDate: currentDate,
      },
      {
        id: 'Past Week',
        beginDate: new Date(addDays(currentDate, -7)),
        endDate: currentDate,
      },
    ];
    console.log('quickSelectOptions', quickSelectOptions);
    return quickSelectOptions;
  }, []);

  const [filterTimeRange, setFilterTimeRange] = useState<Date[]>(() => {
    const currentDate = getCurrentDate();
    const lastWeek = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    lastWeek.setHours(0, 0, 0, 0);
    return [lastWeek, currentDate];
  });

  const [nodes, setNodes] = useState<Value>([{ label: 'All Nodes', id: 'All' }]);
  const [components, setComponents] = useState<Value>([{ label: 'All Component', id: 'All' }]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const trimQuery = debouncedQuery.trim();

  const filter = useMemo(() => {
    let [from, to] = filterTimeRange;
    if (!from) {
      from = new Date();
    }

    return {
      query: trimQuery ? trimQuery : undefined,
      hosts: nodes[0]?.id === 'All' ? undefined : nodes.map((node) => node.id),
      components: components[0]?.id === 'All' ? undefined : components.map((component) => component.id),
      pageNumber,
      pageSize,
      from: from.getTime(),
      to: to ? to.getTime() : undefined,
    } as LogFilter;
  }, [trimQuery, filterTimeRange, nodes, components, pageNumber]);

  const workgroupID = group.workgroup_id;
  // filter workspaces by role
  const workspacesOptions = group.workspaces
    .filter(
      (workspace) =>
        calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id) === 'workspace-admins'
    )
    .map((workspace) => ({
      label: workspace.name,
      id: workspace.workspace_id,
    }));

  const [workspace, setWorkspace] = useState<Value>(
    workspacesOptions.length > 0 ? [{ id: workspacesOptions[0].id, label: workspacesOptions[0].label }] : []
  );
  const workspaceID = (workspace[0]?.id as string) || '';

  const { isFetching, data, isError, error } = useQueryLogs(workgroupID, workspaceID, filter, {
    enabled: !!workspaceID,
  });
  const logComponents = useQueryLogComponentList(workgroupID, workspaceID, { enabled: !!workspaceID });
  const componentsOptions = [{ label: 'All Component', id: 'All' }].concat(
    (logComponents.data || []).map((component) => ({ label: component, id: component }))
  );
  const logHosts = useQueryLogHostList(workgroupID, workspaceID, { enabled: !!workspaceID });
  const hostOptions = [{ label: 'All Nodes', id: 'All' }].concat(
    (logHosts.data || []).map((host) => ({ label: host, id: host }))
  );

  // a maximum of 10,000 logs can be viewed.
  const totalCount = Math.min(data?.totalCount || 0, 10000);

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      })}
    >
      <div className={css({ display: 'flex', gap: '8px', justifyContent: 'flex-start' })}>
        <Select
          clearable={false}
          searchable={false}
          options={workspacesOptions}
          value={workspace}
          onChange={(params) => {
            setWorkspace(params.value);
            setNodes([{ label: 'All Nodes', id: 'All' }]);
            setComponents([{ label: 'All Component', id: 'All' }]);
            setPageNumber(1);
          }}
          getValueLabel={({ option }) => {
            return (
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                })}
              >
                <div
                  className={css({
                    minWidth: '12px',
                    color: theme.colors.gray800,
                  })}
                >
                  <SpaceIcon />
                </div>
                <div>{option.label}</div>
              </div>
            );
          }}
          overrides={{
            Root: {
              style: {
                width: '240px',
              },
            },
          }}
        />
        <Select
          clearable={false}
          searchable={false}
          options={hostOptions}
          value={nodes}
          isLoading={logHosts.isLoading}
          onChange={(params) => {
            setNodes(params.value);
            setPageNumber(1);
          }}
          overrides={{
            Root: {
              style: {
                width: '200px',
              },
            },
          }}
        />
        <Select
          clearable={false}
          searchable={false}
          options={componentsOptions}
          value={components}
          isLoading={logComponents.isLoading}
          onChange={(params) => {
            setComponents(params.value);
            setPageNumber(1);
          }}
          overrides={{
            Root: {
              style: {
                width: '200px',
              },
            },
          }}
        />
        <DatePicker
          value={filterTimeRange}
          formatString={'yyyy/MM/dd'}
          placeholder="YYYY /MM /DD - YYYY /MM /DD"
          onChange={({ date }) => {
            setFilterTimeRange(date as Date[]);
            setPageNumber(1);
          }}
          highlightedDate={new Date()}
          quickSelectOptions={quickSelectOptions}
          quickSelect
          timeSelectStart
          timeSelectEnd
          range
        />
        <Input
          value={query}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
            setPageNumber(1);
          }}
          placeholder="Search"
          startEnhancer={<Search />}
          overrides={{
            Root: {
              style: {
                width: '320px',
                marginLeft: 'auto',
              },
            },
          }}
          clearable={true}
        />
      </div>
      <TableBuilder
        data={data?.logs || []}
        isLoading={isFetching}
        loadingMessage={<LoadingIndicator />}
        emptyMessage={
          isError ? (
            <ErrorDisplay error={error} label="Server error:" />
          ) : (
            <div
              className={css({
                display: 'grid',
                placeContent: 'center',
                marginTop: '16px',
              })}
            >
              No corresponding log found.
            </div>
          )
        }
      >
        <TableBuilderColumn header="Local time" id="time">
          {(row: Log) => {
            return format(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss');
          }}
        </TableBuilderColumn>
        <TableBuilderColumn header="Node" id="node">
          {(row: Log) => row.host}
        </TableBuilderColumn>
        <TableBuilderColumn header="Component" id="component">
          {(row: Log) => row.component}
        </TableBuilderColumn>
        <TableBuilderColumn
          header="Log"
          id="log"
          overrides={{
            TableBodyCell: {
              style: {
                wordBreak: 'break-word',
              },
            },
          }}
        >
          {(row: Log) => <HighLightQuery text={row.log} query={trimQuery} />}
        </TableBuilderColumn>
      </TableBuilder>
      {data && totalCount > 0 ? (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '16px',
            marginTop: '24px',
          })}
        >
          <Body1 color={theme.colors['text.secondary']}>
            {`${(pageNumber - 1) * pageSize + 1} - ${Math.min(
              pageNumber * pageSize,
              totalCount
            )} of ${totalCount} items`}
          </Body1>
          <Pagination
            totalPage={Math.ceil(totalCount / data.pageSize)}
            pageNumber={pageNumber}
            setPageNumber={(page) => setPageNumber(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

function HighLightQuery({ text, query }: { text: string; query: string }) {
  if (!query) {
    return <>{text}</>;
  }
  const lowerText = text.toLowerCase();
  const lowerQuery = query.toLowerCase();
  let index = lowerText.indexOf(lowerQuery);
  if (index === -1) {
    return <>{text}</>;
  }

  const segments = [];

  while (index !== -1) {
    const preIndex = index + query.length;
    segments.push(text.slice(0, index));
    segments.push(<mark>{text.slice(index, index + query.length)}</mark>);
    index = lowerText.indexOf(lowerQuery, index + query.length);
    if (index === -1) {
      segments.push(text.slice(preIndex));
    }
  }

  return <>{segments}</>;
}
