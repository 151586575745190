import { Drawer, DrawerBody, DrawerHeader, DrawerAction } from '@/components/Drawer';
import { FormControl } from '@/components/FormControl';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { STYLE_TYPE } from 'baseui/checkbox';
import { Button } from '@tigergraph/app-ui-lib/button';
import { WorkGroupT } from '@/pages/workgroup/type';
import { GroupIcon } from '@/pages/home/icons';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { PlatformIcon } from '@/pages/admin/settings/cloud_provider/CloudProviderCategory';
import { useLogFeatureEnabled, useMutationUpdateWorkGroup, useQueryGetGroups } from '@/pages/workgroup/hook';
import { useForm, Controller } from 'react-hook-form';
import { NameRules } from '@/pages/workgroup/form/name';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { useState } from 'react';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils';

export default function WorkgroupSetting({
  workgroup,
  isOpen,
  onClose,
}: {
  workgroup: WorkGroupT;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [css, theme] = useStyletron();
  const logFeatureEnabled = useLogFeatureEnabled();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<{ name: string; enable_application_log: boolean }>({
    defaultValues: {
      name: workgroup.name,
      enable_application_log: workgroup.enable_application_log || false,
    },
  });

  const [showWarning, setShowWarning] = useState(false);

  const name = watch('name');
  const enable_application_log = watch('enable_application_log');

  const groupsQuery = useQueryGetGroups();
  const updateGroup = useMutationUpdateWorkGroup();

  const isLogChanged =
    (enable_application_log && !workgroup.enable_application_log) ||
    (!enable_application_log && workgroup.enable_application_log);
  const isNameChanged = name !== workgroup.name;

  const handleSave = () => {
    if (isLogChanged) {
      setShowWarning(true);
    } else {
      handleSaveConfirm();
    }
  };

  const handleSaveConfirm = () => {
    updateGroup.mutate(
      {
        group_id: workgroup.workgroup_id,
        workgroup_name: name,
        enable_application_log,
      },
      {
        onSuccess() {
          showToast({
            kind: 'positive',
            message: 'Workgroup settings updated successfully',
          });
          onClose();
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(error)}`,
          });
        },
      }
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <DrawerHeader>
        <GroupIcon width={24} height={24} /> Workgroup Settings
      </DrawerHeader>
      <DrawerBody>
        <form onSubmit={handleSubmit(handleSave)}>
          <FormControl label="Workgroup Name" error={errors?.name?.message}>
            <Controller
              rules={{
                ...NameRules,
                validate(value) {
                  // check if the name duplicate with other workgroups
                  const groupNames =
                    groupsQuery.data?.Result?.filter((g) => g.workgroup_id !== workgroup.workgroup_id).map(
                      (i) => i.name
                    ) || [];
                  console.log(groupNames);
                  if (groupNames?.includes(value)) {
                    return `'${value}' is exist, please use another name`;
                  }
                },
              }}
              control={control}
              name="name"
              render={({ field }) => (
                <Input placeholder="Enter workgroup name" {...field} error={!!errors?.name} autoComplete="off" />
              )}
            />
          </FormControl>
          <FormControl label="Cloud Provider">
            <Input
              disabled
              startEnhancer={() => (
                <div
                  className={css({
                    display: 'flex',
                    width: '400px',
                    alignItems: 'center',
                    gap: '8px',
                    color: theme.colors['text.disabled'],
                  })}
                >
                  <PlatformIcon platform={workgroup.platform} width={18} />
                  {workgroup.cloud_provider_type === 'public' ? workgroup.platform : workgroup.cloud_provider_name}
                  {workgroup.cloud_provider_type === 'private' ? (
                    <Tag kind="custom" color={theme.colors['background.accent.gray.bolder']} closeable={false}>
                      BYOC
                    </Tag>
                  ) : null}
                </div>
              )}
            />
          </FormControl>
          <FormControl label="Region">
            <Input value={workgroup.region} disabled />
          </FormControl>
          {logFeatureEnabled && (
            <div>
              <h2 className={css({ fontSize: '16px', lineHeight: '24px', fontWeight: 500 })}>Workgroup Settings</h2>
              <FormControl caption="Enable or disable log collection for this workgroup.">
                <Controller
                  name="enable_application_log"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Checkbox
                      checked={value}
                      labelPlacement="left"
                      onChange={() => onChange(!value)}
                      {...field}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      overrides={{
                        Label: {
                          style: {
                            fontWeight: 500,
                            marginRight: 'auto',
                          },
                        },
                      }}
                    >
                      Enable Log Collection
                    </Checkbox>
                  )}
                />
              </FormControl>
            </div>
          )}
        </form>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" size="large" onClick={onClose} kind="secondary" disabled={updateGroup.isLoading}>
          Cancel
        </Button>
        <Button
          size="large"
          onClick={handleSubmit(handleSave)}
          isLoading={updateGroup.isLoading}
          disabled={updateGroup.isLoading || !(isNameChanged || isLogChanged)}
        >
          Save
        </Button>
      </DrawerAction>
      <Modal onClose={() => setShowWarning(false)} isOpen={showWarning}>
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          Modifying the Log Collection setting will restart all workspaces in this workgroup. Do you want to proceed?
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="secondary" onClick={() => setShowWarning(false)}>
            Cancel
          </ModalButton>
          <ModalButton
            onClick={() => {
              setShowWarning(false);
              handleSaveConfirm();
            }}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </Drawer>
  );
}
