import { axiosController } from '@/lib/network';
import { LogFilter, LogResult } from './type';

export async function fetchComponentList(workgroupID: string, workspaceID: string): Promise<string[]> {
  const response = await axiosController.get(`/v2/log/workgroups/${workgroupID}/workspaces/${workspaceID}/components`);
  return response.data['Result'];
}

export async function fetchHostList(workgroupID: string, workspaceID: string): Promise<string[]> {
  const response = await axiosController.get(`/v2/log/workgroups/${workgroupID}/workspaces/${workspaceID}/hosts`);
  return response.data['Result'];
}

export async function fetchLog(workgroupID: string, workspaceID: string, logFilter: LogFilter): Promise<LogResult> {
  const response = await axiosController.post(
    `/v2/log/workgroups/${workgroupID}/workspaces/${workspaceID}/search`,
    logFilter
  );
  return response.data['Result'];
}
